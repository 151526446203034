import React from 'react';
import Form from './Form';
import WelcomeComponent from '../../../other/welcome/WelcomeComponent';
import './Form.css';

const ABHAForm = () => {
const content = "ABHA - Ayushman Bharat Health Account";
  return (
    <div className=' loan-card '>
      <div className='paperStyle-loan-loan'>
      <WelcomeComponent props={content} />
      <Form />
    </div>
    </div>  )
}

export default ABHAForm