import React from "react";
import "./Welcome.css";

const WelcomeComponent = ({ props }) => {
  return (
    <div>
      <h1 style={{ fontWeight: "bold" }} className="welcome">
        Welcome
      </h1>
      <p className="welcome-status">{props}</p>
    </div>
  );
};

export default WelcomeComponent;
