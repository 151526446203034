// src/AppRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
// import PatientInformation from "./components/patient-information/patient-information.component";
// import ClaimStatusView from "./components/claim-status/claim-status.component";
// import Base from "./components/Base";
// import LeadFormSuccess from "./components/app/LoanManager/success/LeadFormSuccess";
// import Loan from "./components/pages/Loan";
import Abdm from "./components/pages/Abdm";

const AppRoutes = ({
  isLoggedIn,
  setLoggedIn,
  visibility,
  setVisibility,
  claimInformation,
  setClaimInformation,
  hospitalQRID,
}) => {
  return (
    <Routes>
      {/* <Route
        path="/patient/information/:hospitalQRID"
        element={
          <div className={isLoggedIn ? `${visibility} disappear` : "info"}>
            <Base
              props={
                <PatientInformation
                  hospitalQRID={hospitalQRID}
                  isLoggedIn={isLoggedIn}
                  updateStatus={(claimInformation) => {
                    setLoggedIn(null !== claimInformation);
                    setClaimInformation(claimInformation);
                    setTimeout(() => {
                      setVisibility("hide");
                    }, 1000);
                  }}
                />
              }
            />
          </div>
        }
      /> */}
      {/* <Route
        path="/patient/information/:hospitalQRID/claim_status"
        element={
          <div className={isLoggedIn ? `appear` : "hide"}>
            <ClaimStatusView
              isLoggedIn={isLoggedIn}
              claimInformation={claimInformation}
            />
          </div>
        }
      /> */}

      {/* <Route path="/patient/information/loan" element={<Loan />} /> */}
      <Route path="" element={<Abdm />} />

      {/* <Route
        path="/patient/information/loan/status"
        element={<LeadFormSuccess />}
      /> */}
    </Routes>
  );
};

export default AppRoutes;
