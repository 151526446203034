// src/App.js
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import CheckLink from "./components/check-link-page/check-link-page.component";
import AppRoutes from "./AppRoutes";
import { getHospitalQRID } from "./utils";

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [visibility, setVisibility] = useState("");
  const [claimInformation, setClaimInformation] = useState(null);

  const hospitalQRID = getHospitalQRID();
  localStorage.setItem("hospitalQRID", hospitalQRID);

  // if (!hospitalQRID) {
  //   return (
  //     <div className="app">
  //       <CheckLink />
  //     </div>
  //   );
  // }

  return (
    <div className="app h-fit">
      <BrowserRouter>
        <AppRoutes
          isLoggedIn={isLoggedIn}
          setLoggedIn={setLoggedIn}
          visibility={visibility}
          setVisibility={setVisibility}
          claimInformation={claimInformation}
          setClaimInformation={setClaimInformation}
          hospitalQRID={hospitalQRID}
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
