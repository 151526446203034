import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import "./Form.css";

const Loader = () => (
  <div className="loader">Loading...</div>
);

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [aadhaar, setAadhaar] = useState("");
  const [mobile, setMobile] = useState("");
  const [abhaAddress, setAbhaAddress] = useState("");
  const [otp, setOtp] = useState("");
  const [txnId, setTxnId] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [consent, setConsent] = useState(false);
  const [resendAttempts, setResendAttempts] = useState(0);
  const [resendTimer, setResendTimer] = useState(0);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [xToken, setXToken] = useState("");
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(false);

  const generateOtp = useCallback(
    async (advanceStep = false) => {
      setLoading(true);
      try {
        const response = await axios.post(
          "http://localhost:8000/abdm-hs/generate-otp/",
          {
            loginId: aadhaar,
            txnId: "",
            scope: ["abha-enrol"],
            loginHint: "aadhaar",
            otpSystem: "aadhaar",
          }
        );
        if (response.data.txnId) {
          setTxnId(response.data.txnId);
          setMessage(response.data.message);
          if (advanceStep) {
            setStep((prevStep) => prevStep + 1);
          }
          setResendAttempts(0);
          setResendTimer(60);
        } else {
          setError("Failed to generate OTP. Please try again.");
        }
      } catch (error) {
        setError(
          "An error occurred while generating OTP. Please try again later."
        );
      } finally {
        setLoading(false);
      }
    },
    [aadhaar]
  );

  const verifyOtp = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:8000/abdm-hs/verify-otp/",
        {
          authData: {
            otp: {
              otpValue: otp,
              txnId: txnId,
              mobile: mobile,
            },
          },
        }
      );
      setXToken(response.data);
      if (response.data) {
        console.log("OTP verified successfully:", response.data);
        setStep((prevStep) => prevStep + 1);
        fetchEnrollmentSuggestions();
      } else {
        setError("OTP verification failed. Please try again.");
      }
    } catch (error) {
      setError(
        "An error occurred while verifying OTP. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  }, [otp, txnId, mobile]);

  const fetchEnrollmentSuggestions = useCallback(async () => {
    if (!txnId) {
      console.error("txnId is missing or invalid.");
      setError("txnId is missing or invalid.");
      return;
    }
      setLoading(true);
    try {
      const response = await axios.get(
        "http://localhost:8000/abdm-hs/enrollment-suggestion/",
        {
          headers: {
            "Transaction-Id": txnId, 
          },
        }
      );

      const addressList = Array.isArray(response.data.abhaAddressList)
        ? response.data.abhaAddressList
        : [];
      setSuggestions(addressList);
      setFilteredSuggestions(addressList.slice(0, 5));
    } catch (error) {
      console.error("Error fetching enrollment suggestions:", error);
      setError("Failed to fetch enrollment suggestions.");
    } finally {
      setLoading(false);
    }
  }, [txnId]); 
  
  
  const enrollAbhaAddress = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:8000/abdm-hs/api/enroll-abha-address/",
        {
          txnId: txnId,
          abhaAddress: abhaAddress,
          preferred: 1,
        },
        {
          headers: {
            "X-token": `Bearer ${xToken.tokens?.token}`,
          },
        }
      );
      console.log("Enrollment response:", response.data);
      if (response.data) {
        setStep((prevStep) => prevStep + 1);
      } else {
        setError("Please try again.");
      }
    } catch (error) {
      console.error("Error enrolling ABHA address:", error);
      setError("Failed to enroll ABHA address.");
    } finally {
      setLoading(false);
    }
  }, [txnId, abhaAddress, xToken]);

  const GetAbhaCard = useCallback(async () => {
    if (!xToken?.tokens?.token) {
      console.error("Token is missing or invalid.");
      setError("Token is missing or invalid.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        "http://localhost:8000/abdm-hs/api/get-abha-card/",
        {
          headers: {
            "X-token": `Bearer ${xToken.tokens.token}`,
          },
          responseType: "arraybuffer",
        }
      );

      const imageBlob = new Blob([response.data], { type: "image/png" });
      const imageUrl = URL.createObjectURL(imageBlob);

      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = "abha-card.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error fetching ABHA card:", error);
      setError("Failed to fetch ABHA card.");
    } finally {
      setLoading(false);
    }
  }, [xToken?.tokens?.token]);

  useEffect(() => {
    if (Array.isArray(suggestions)) {
      setFilteredSuggestions(
        suggestions
          .filter((address) =>
            address.toLowerCase().includes(filterText.toLowerCase())
          )
          .slice(0, 5)
      );
    }
  }, [filterText, suggestions]);

  const handleResendOtp = useCallback(() => {
    if (resendAttempts < 2 && resendTimer === 0) {
      generateOtp();
      setResendAttempts((prev) => prev + 1);
      setResendTimer(60);
    }
  }, [resendAttempts, resendTimer, generateOtp]);

  const handleNext = useCallback(async () => {
    try {
      if (step === 1) {
        if (/^\d{12}$/.test(aadhaar)) {
          await generateOtp(true);
        } else {
          setError("Aadhaar Number is not valid");
        }
      } else if (step === 2) {
        await verifyOtp();
      } else if (step === 3) {
        if (consent) {
          await enrollAbhaAddress();
          await fetchEnrollmentSuggestions();
          setStep((prevStep) => prevStep + 1);
        } else {
          setError("You must provide consent to proceed.");
        }
      }
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
    }
  }, [
    step,
    aadhaar,
    txnId,
    otp,
    mobile,
    consent,
    generateOtp,
    verifyOtp,
    enrollAbhaAddress,
    fetchEnrollmentSuggestions,
  ]);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [resendTimer]);

  return (
    <div className="flex items-center justify-center bg-gray-100 p-6">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        {loading && <Loader />}
        {!loading && step === 1 && (
          <div>
            <h2 className="text-2xl font-bold mb-4">
              Create ABHA Number Using Aadhaar
            </h2>
            <h6 className="text-sm font-sans mb-4">Enter Aadhaar Number</h6>
            <input
              type="text"
              value={aadhaar}
              onChange={(e) => setAadhaar(e.target.value)}
              placeholder="Enter 12-digit Aadhaar number"
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <div className="scrollable-box mb-4">
              <p>
                I, hereby declare that I am voluntarily sharing my Aadhaar
                number and demographic information issued by UIDAI, with
                National Health Authority (NHA) for the sole purpose of creation
                of ABHA number. I understand that my ABHA number can be used and
                shared for purposes as may be notified by ABDM from time to time
                including provision of healthcare services. Further, I am aware
                that my personal identifiable information (Name, Address, Age,
                Date of Birth, Gender and Photograph) may be made available to
                the entities working in the National Digital Health Ecosystem
                (NDHE) which inter alia includes stakeholders and entities such
                as healthcare professionals (e.g. doctors), facilities (e.g.
                hospitals, laboratories) and data fiduciaries (e.g. health
                programmes), which are registered with or linked to the Ayushman
                Bharat Digital Mission (ABDM), and various processes there
                under. I authorize NHA to use my Aadhaar number for performing
                Aadhaar based authentication with UIDAI as per the provisions of
                the Aadhaar (Targeted Delivery of Financial and other Subsidies,
                Benefits and Services) Act, 2016 for the aforesaid purpose. I
                understand that UIDAI will share my e-KYC details, or response
                of “Yes” with NHA upon successful authentication. I have been
                duly informed about the option of using other IDs apart from
                Aadhaar; however, I consciously choose to use Aadhaar number for
                the purpose of availing benefits across the NDHE. I am aware
                that my personal identifiable information excluding Aadhaar
                number / VID number can be used and shared for purposes as
                mentioned above. I reserve the right to revoke the given consent
                at any point of time as per provisions of Aadhaar Act and
                Regulations.
              </p>
            </div>
            <div className="mb-4">
              <input
                type="checkbox"
                checked={agreeTerms}
                onChange={(e) => setAgreeTerms(e.target.checked)}
                id="agreeTerms"
                className="mr-2"
              />
              <label htmlFor="agreeTerms">
                I agree to the Terms and Conditions
              </label>
            </div>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <button
              onClick={handleNext}
              className="loansubmitButton"
              disabled={!agreeTerms}
            >
              Continue
            </button>
          </div>
        )}
        {!loading && step === 2 && (
          <div>
            <h2 className="text-2xl font-bold mb-4">Step 2: Enter OTP</h2>
            <p className="mb-4">{message}</p>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <input
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter Your Mobile Number"
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <div className="flex flex-col space-y-4">
              <button onClick={handleNext} className="loansubmitButton">
                Verify OTP
              </button>
              <button
                onClick={handleResendOtp}
                className="loansubmitButton"
                disabled={resendAttempts >= 2 || resendTimer > 0}
              >
                {resendTimer > 0
                  ? `Resend OTP (${resendTimer}s)`
                  : "Resend OTP"}
              </button>
            </div>
          </div>
        )}
        {!loading && step === 3 && (
          <div>
            <div>
              <h3 className="text-lg font-bold mb-4">
                Enrollment Suggestions:
              </h3>
              <input
                type="text"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Filter suggestions"
                className="border p-2 mb-4 w-full"
              />
              <input
                type="text"
                value={abhaAddress}
                onChange={(e) => setAbhaAddress(e.target.value)}
                placeholder="Abha Address"
                className="border p-2 mb-4 w-full"
              />
              {error && <p className="text-red-500">{error}</p>}
              <ul>
                {filteredSuggestions.length > 0 ? (
                  filteredSuggestions.map((address, index) => (
                    <li key={index} className="mb-2">
                      {address}
                    </li>
                  ))
                ) : (
                  <li>No suggestions available.</li>
                )}
              </ul>
            </div>
            <div className="mt-7">
              <button onClick={enrollAbhaAddress} className="loansubmitButton">
                Continue
              </button>
            </div>
          </div>
        )}
        {!loading && step === 4 && (
          <div>
            <h2 className="text-2xl font-bold mb-4">Step 4: Confirmation</h2>
            <p className="mb-4">
              Your ABHA address has been enrolled successfully. You can download
              your ABHA card or proceed with other actions.
            </p>
            <div className="mt-7">
              <button onClick={GetAbhaCard} className="loansubmitButton">
                Download ABHA Card
              </button>
            </div>
            {/* You can add more actions or information for step 4 here */}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiStepForm;
