import React, { useState } from "react";
import { CustomPaperTitle } from "../Navbar/Logo/CustomPaperTitle";
import Navbar from "../Navbar/NavBar";
import LearnMoreComponent from "../Footer/LearnMoreComponent";
import ABHAForm from "../app/LoanManager/ABHAForm/ABHAForm";

const Abdm = () => {
    const [isNavbarVisible, setNavbarVisible] = useState(true);

    const toggleNavbarVisibility = () => {
      setNavbarVisible(!isNavbarVisible);
    };
  return (
    <>
    <div>
    <CustomPaperTitle />
      {isNavbarVisible && <Navbar />}

      <div className={isNavbarVisible ? "content-view" : ""}>
        <div className="status-content">
        </div>
        <div>
        <ABHAForm/>
        </div>
      </div>

      {isNavbarVisible && <LearnMoreComponent />}
    </div>
    </> 
     )
}

export default Abdm