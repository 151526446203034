import React from "react";

const LearnMoreComponent = () => {
  return (
    <div className="learn-more-container">
      <p className="learn-more-text">
        To learn more visit{" "}
        <a href="https://www.healspan.com/" className="website-link">
          our website.
        </a>
      </p>
      <a href="mailto:info@healspan.com" className="email-link">
        info@healspan.com
      </a>
    </div>
  );
};
export default LearnMoreComponent;
