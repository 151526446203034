import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./NavBar.css";

const getHospitalQRID = () => {
  return localStorage.getItem("hospitalQRID");
};

const Navbar = () => {
  const [hospitalQRID, setHospitalQRID] = useState(getHospitalQRID());
  const location = useLocation();

  useEffect(() => {
    const storedHospitalQRID = getHospitalQRID();
    if (storedHospitalQRID !== hospitalQRID) {
      setHospitalQRID(storedHospitalQRID);
    }
  }, [location.pathname, hospitalQRID]);

  useEffect(() => {
    // const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
    // const url = new URL(fullUrl);

    // console.log("Protocol:", url.protocol);
    // console.log("Hostname:", url.hostname);
    // console.log("Pathname:", url.pathname);
    // console.log("Search Params:", url.search);
    // console.log("Hash:", url.hash);
  }, [location]);

  const navItems = [
    // {
    //   label: "Claim",
    //   option: "claim",
    //   to: `/patient/information/${hospitalQRID}`,
    // },
    // { label: "Loan", option: "loan", to: "/patient/information/loan" },
    { label: "Create Abha Card", option: "abdm", to: "" },
  ];

  return (
    <div className="navbar">
      {navItems.map((item, index) => (
        <NavLink
          key={index}
          to={item.to}
          className={({ isActive }) => {
            const isMatching = location.pathname.startsWith(item.to);
            return `nav-item${isMatching ? " active" : ""}`;
          }}
          exact
        >
          {item.label}
        </NavLink>
      ))}
    </div>
  );
};

export default Navbar;
