import React from "react";

export const CustomPaperTitle = () => {
  return (
    <div className="logoContainerStyle">
      <div>
        <div
          className="company-slogan from-neutral-900"
          style={{ fontSize: '30px', fontWeight: 'bold' }} // Inline CSS for font size
        >
          Mediscus Healthcare
        </div>
      </div>
    </div>
  );
};
